<template>
  <div :class="classObj" class="app-wrapper">
    <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside"></div>
    <sidebar v-if="!hideSidebar" class="sidebar-container" />
    <div class="main-container" :class="{'is-wujie': !fixedHeader}">
      <div :class="{'fixed-header':fixedHeader}">
        <navbar v-if="!hideHeader" />
      </div>
      <app-main />
    </div>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain } from './components'
import ResizeMixin from './mixin/ResizeHandler'

export default {
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
    AppMain
  },
  mixins: [ResizeMixin],
  computed: {
    hideHeader() {
      return window.$wujie?.props?.hideHeader
    },
    hideSidebar() {
      return window.$wujie?.props?.hideSidebar
    },
    sidebar() {
      return this.$store.state.setting.sidebar
    },
    device() {
      return this.$store.state.setting.device
    },
    fixedHeader() {
      return !window.$wujie
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile' && !window.$wujie,
      }
    }
  },
  methods: {
    handleClickOutside() {
      if (window.$wujie) return
      this.$store.dispatch('setting/closeSideBar', { withoutAnimation: false })
    }
  }
}
</script>

<style lang="less" scoped>
// @import "~@/styles/mixin.scss";
@import "~@/styles/variables.less";

.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
.drawer-bg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - @sideBarWidth);
  transition: width 0.28s;
  z-index: 9;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
</style>
