// /**
//  * @author krisjczhang
//  * @email krisjczhang@tencent.com
//  * @create date 2020-03-30 15:59:05
//  * @modify date 2020-03-30 15:59:05
//  * @desc [人员信息处理]]
//  */

import { newformatApi } from '../baseApi';

// 根据关键字拉取员工信息
export async function newSearchStaffList(v, Limit) {
  const result = await newformatApi({
    Action: 'Get',
    Method: 'StaffInfo',
    SystemId: '2',
    Keyword: v,
    Limit: parseInt(Limit, 10),

  });
  const { data } = result;
  return data.data;
}