import Cookies from 'js-cookie';

const state = {
    sidebar: {
        opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
        withoutAnimation: false
    },
    device: 'desktop',
    rootRouter: sessionStorage.rootRouter ? sessionStorage.rootRouter : 'appManage',
    RouterPath: '',
    RouterData: {}
};

const mutations = {
    TOGGLE_SIDEBAR: state => {
        state.sidebar.opened = !state.sidebar.opened;
        state.sidebar.withoutAnimation = false;
        if (state.sidebar.opened) {
            Cookies.set('sidebarStatus', 1);
        } else {
            Cookies.set('sidebarStatus', 0);
        }
    },
    SET_SIDEBAR: (state, status) => {
        state.sidebar.opened = status;
        state.sidebar.withoutAnimation = false;
        if (state.sidebar.opened) {
            Cookies.set('sidebarStatus', 1);
        } else {
            Cookies.set('sidebarStatus', 0);
        }
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
        Cookies.set('sidebarStatus', 0);
        state.sidebar.opened = false;
        state.sidebar.withoutAnimation = withoutAnimation;
    },
    TOGGLE_DEVICE: (state, device) => {
        state.device = device;
    },
    CHANGE_ROOTROUTER: (state, newRouter) => {
        state.rootRouter = newRouter;
    },
    CHANGE_ROUTERPATH: (state, newRouterpATH) => {
        state.RouterPath = newRouterpATH;
    },
    CHANGE_ROUTER: (state, routerData) => {
      state.RouterData = routerData;
    }
};

const actions = {
    toggleSideBar({commit}) {
        commit('TOGGLE_SIDEBAR');
    },
    setSidebar({commit}, status) {
        commit('SET_SIDEBAR', status);
    },
    closeSideBar({commit}, {withoutAnimation}) {
        commit('CLOSE_SIDEBAR', withoutAnimation);
    },
    toggleDevice({commit}, device) {
        commit('TOGGLE_DEVICE', device);
    },
    changeRootRouter({commit}, newRouter) {
        commit('CHANGE_ROOTROUTER', newRouter);
    },
    changeRouterPath({commit}, newRouterPath) {
      commit('CHANGE_ROUTERPATH', newRouterPath);
    },
    changeRouter({commit}, routerData) {
      commit('CHANGE_ROUTER', routerData);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
