import Layout from '@/layout';

// 资产管理
export default [
    {
        path: '/assetManage',
        redirect: '/assetManage/tool',
    },
    {
        path: '/assetManage/tool',
        component: Layout,
        name: 'tool',
        meta: { title: '资产管理工具', icon: 'el-icon-s-order' },
        children: [
            {
                path: 'idcpAssetTool',
                name: 'idcpAssetTool',
                component: () => import('@/views/assetManage/tool/idcpAssetTool/index.vue'),
                meta: { title: '园区资产管理工具', icon: 'el-icon-document' },
            }
        ]
    },
    {
        path: '/assetManage/check',
        component: Layout,
        name: 'check',
        meta: { title: '盘点', icon: 'el-icon-s-order' },
        children: [
            {
                path: 'a1',
                name: 'a1',
                component: () => import('@/views/appManage/test'),
                meta: { title: '盘点规划', icon: 'el-icon-document' },
            },
            {
                path: 'a2',
                name: 'a2',
                component: () => import('@/views/appManage/test'),
                meta: { title: '盘点派单', icon: 'el-icon-document' },
            },
            {
                path: 'a3',
                name: 'a3',
                component: () => import('@/views/appManage/test'),
                meta: { title: '盘点记录', icon: 'el-icon-document' },
            },
            {
                path: 'a4',
                name: 'a4',
                component: () => import('@/views/appManage/test'),
                meta: { title: '盘点总结', icon: 'el-icon-document' },
            }
        ]
    }
];