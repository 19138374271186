import axios from 'axios';
import store from '@/store';

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // 跨域则可以开启cookie开关
  // timeout: 5000 // 超时
});

// 提取路由path
function extractRoutePath(routerData) {
  let routePath = routerData.path;
  if (!routerData.matched || routerData.matched.length === 0) {
    return routePath;
  }
  const matchedItem = routerData.matched[routerData.matched.length - 1];
  if (!matchedItem || !matchedItem.path) {
    return routePath;
  }
  const matchedPath = matchedItem.path;
  let pubPathItems = [];
  for (let i = 0; i < routePath.length && i < matchedPath.length; i++) {
    if (routePath[i] !== matchedPath[i]) {
      break;
    }
    pubPathItems.push(routePath[i]);
  }
  if (pubPathItems.length === 0) {
    return routePath;
  }
  if (pubPathItems[pubPathItems.length - 1] === '/' && pubPathItems.length >= 2) {
    pubPathItems = pubPathItems.slice(0, pubPathItems.length - 1);
  }
  routePath = pubPathItems.join('');
  return routePath;
}

// 提取请求方法名
function extractMethodName(postData) {
  let methodName = '';
  try {
    if (!postData) {
      return '';
    }
    let jsonData = {};
    if (typeof postData === 'string') {
      jsonData = JSON.parse(postData);
    } else {
      jsonData = postData;
    }
    if (!jsonData.Action || !jsonData.Method) {
      return '';
    }
    const action = jsonData.Action;
    const method = jsonData.Method;
    if ((action === 'QueryData' || action === 'OperateData') && jsonData.SchemaId) {
      methodName = `${action}-${method}[SchemaId=${jsonData.SchemaId}]`;
    } else {
      methodName = `${action}-${method}`;
    }
  } catch (e) {
    return '';
  }
  return methodName;
}

// 请求拦截器 request interceptor
service.interceptors.request.use(
  (request) => {
    // 在这里处理请求头编码,兼容请求头值中含有中文
    if (request.headers) {
      Object.keys(request.headers).forEach(key => {
        if (typeof request.headers[key] === 'string') {
          // 对请求头中的中文键值进行编码
          request.headers[key] = encodeURIComponent(request.headers[key]);
        }
      });
    }

    // 配置识别为ajax接口
    request.headers['X-Requested-With'] = 'XMLHttpRequest';
    const routerData = store.state.setting.RouterData;
    if (routerData && routerData.path) {
      const routePath = extractRoutePath(routerData);
      request.headers['route-path'] = routePath;
    }
    if (routerData.meta && routerData.meta.title) {
      const routePathName = btoa(encodeURIComponent(routerData.meta.title));
      request.headers['route-path-name'] = routePathName;
    }
    if (request.data && request.url.indexOf('?') === -1) {
      const methodName = extractMethodName(request.data);
      // request.url = `${request.url}?method=${methodName}`;
      request.url = `${request.url}`;
    }
    request.headers.AuthToken = localStorage.getItem('AuthToken');
    request.headers.UserId = encodeURIComponent(localStorage.getItem('UserId'));

    return request;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// 回复拦截器 response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    // 二进制数据则直接返回
    if (response.request.responseType === 'blob' || response.request.responseType === 'arraybuffer') {
      return response.data
    }
    // 这里可以对成功的请求进行处理，可以根据约定好的code代码来表示请求是否正常
    // 例如这里代码不是20000则表示有错
    if (response?.data?.status === 401) {
      localStorage.clear();
      if (location.pathname == '/operateManage/business/agency') {
        location.href = '/translate/business/login';
      } else {
        const url = window.parent.location.href;
        localStorage.setItem('nowUrl', url);
        location.href = `${location.origin}/translate/business/login?from=${location.href}`;
      }
    }
    return response;
  },
  (error) => {
    console.log(error);
    console.log('error:', error.message);

    return error;
  }
);

export default service;
