import Layout from '@/layout';

export default [
  {
    path: '/translate',
    redirect: '/translate/business',
  },
  {
    path: '/translate/business',
    component: Layout,
    name: 'business',
    redirect: '/translate/business/login',
    meta: { title: '二维码登录页', icon: 'el-icon-data-line' },
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/appManage/ticketModule/login'),
        meta: { title: '二维码登录页', icon: 'el-icon-files' },
        hidden: true,
      },
      {
        path: 'translate',
        name: 'translate',
        component: () => import('@/views/appManage/ticketModule/translate'),
        meta: { title: '登陆页面', icon: 'el-icon-files' },
        hidden: true,
      },
    ],
  },
];
