import request from '@/http/request';
import { Message } from 'element-ui';

// 定义的state初始值
const state = {
  name: '',
  isAdmin: false,
  chnName: '',
  department: '',
  departmentArr: [],
  positionName: '',
  workPlaceID: '',
  ticket: '',
  keji_account: '',
  keji_user_name: '',
  avatar: '',
};

// 定义的state的初始值方法，传入state或者额外的方法，然后利用vuex的双向数据驱动进行值的改变
// 可通过this.$store.commit(' ')调用，但是触发的是同步事件
const mutations = {
  SET_USER_INFO(state, userInfo) {
    state.keji_account = userInfo.keji_account;
    state.keji_user_name = userInfo.keji_user_name;
    state.avatar = userInfo.thumb_avatar;
  },
  SET_TICKET(state, ticket) {
    state.ticket = ticket;
  },
};

// 使用actions的好处在于不会触发同步时间，而是异步事件
// actions里面自定义的函数接收一个context参数和要变化的形参，context与store实例具有相同的方法和属性，所以它可以执行context.commit(' ')
const actions = {
  // 获取用户信息
  async getUserInfo(context) {
    try {
    } catch (err) {
      Message.error({ message: `获取用户信息错误：${err.message}` });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
