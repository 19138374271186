import Vue from 'vue';
import VueRouter from 'vue-router';
import translate from './modules/translate';
import appManage from './modules/appManage';
import baseRouter from './baseRouter';
import personnel from './modules/personnel';
import facility from './modules/facility';
import projectManage from './modules/projectManage';
import assetManage from './modules/assetManage';
import partner from './modules/partner';
import knowledgeBase from './modules/knowledgeBase';
import aiManage from './modules/aiManage';
import Login from '../views/Login.vue';
import idcpManage from './modules/idcpManage';
import loginApi from '../api/login/login';

Vue.use(VueRouter);
const routes = new Set([
  ...personnel.map(route => ({ ...route, meta: { requiresAuth: true } })),
  ...translate.map(route => ({ ...route, meta: { requiresAuth: true } })),
  ...appManage.map(route => ({ ...route, meta: { requiresAuth: true } })),
  ...baseRouter.map(route => ({ ...route, meta: { requiresAuth: true } })),
  ...facility.map(route => ({ ...route, meta: { requiresAuth: true } })),
  ...projectManage.map(route => ({ ...route, meta: { requiresAuth: true } })),
  ...assetManage.map(route => ({ ...route, meta: { requiresAuth: true } })),
  ...partner.map(route => ({ ...route, meta: { requiresAuth: true } })),
  ...knowledgeBase.map(route => ({ ...route, meta: { requiresAuth: true } })),
  ...aiManage.map(route => ({ ...route, meta: { requiresAuth: true } })),
  ...idcpManage.map(route => ({ ...route, meta: { requiresAuth: true } })),
  { path: '/login', component: Login },
]);

const router = new VueRouter({
  mode: 'history',
  routes,
  translate,
  appManage,
  baseRouter,
  personnel,
  facility,
  projectManage,
  assetManage,
  partner,
  knowledgeBase,
  aiManage,
  idcpManage
});

export const ROUTES = [
  { name: 'appManage', title: 'IT运维' },
  { name: 'facility', title: '设施运维' },
  { name: 'assetManage', title: '资产管理' },
  { name: 'personnel', title: '团队管理' },
  { name: 'idcpManage', title: '智慧园区' },
  { name: 'projectManage', title: '项目管理' },
  { name: 'partner', title: '合作伙伴' },
  { name: 'knowledgeBase', title: '知识库' },
  { name: 'aiManage', title: 'AI智能体' },
  
].map(({ name, title, isFlat }) => ({
  name,
  title,
  path: `/${name}`,
  isFlat,
  children: router.options[name].filter((route) => route.meta),
}));

// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const AuthToken = localStorage.getItem('AuthToken');
    const UserId = localStorage.getItem('UserId');
    if (AuthToken) {
      // 查询AuthToken是否有效
      const get_AuthToken_statu = loginApi.validateToken(AuthToken,UserId); //固定返回值
      if (get_AuthToken_statu) {
        next();
      } else {
        next('/login');
      }
    } else {
      next('/login');
    }
  } else {
    next();
  }
});


export default router;
