import Layout from '@/layout';

// 合作伙伴
export default [
    {
        path: '/partner',
        redirect: '/partner/partnerShow',
    },
    {
        path: '/partner/partnerShow',
        component: Layout,
        name: '/partnerShow',
        meta: { title: '合作伙伴', icon: 'el-icon-s-order' },
        children: [
            {
                path: 'partnerShowImage',
                name: 'partnerShowImage',
                component: () => import('@/views/partner/partnerShow/index'),
                meta: { title: '合作伙伴', icon: 'el-icon-document' },
            }
        ]
    }
];