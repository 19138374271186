<template>
  <el-select
    :value="selectedUser"
    class="w"
    remote
    :disabled="disabled"
    :multiple="multiple"
    clearable
    filterable
    value-key="fullName"
    :allow-create="allowCreate"
    :placeholder="placeholder"
    :remote-method="searchUser"
    :loading="searchLoading"
    :default-first-option="true"
    @change="onSelectChange"
    @paste.native="onPaste"
    @visible-change="onBlur"
  >
    <el-option
      v-for="(item, index) in filterUserList"
      :key="index"
      :label="item.fullName"
      :value="item.name"
    >
      <div class="user-item">
        <span class="f14 color-ntext">
          {{ item.fullName }}
        </span>
      </div>
    </el-option>
  </el-select>
</template>

<script>
import { newSearchStaffList } from '@/api/person/personApi';
export default {
  name: 'UserSelect',
  components: {
  },
  model: {
    prop: 'selected',
    event: 'user-select-change',
  },
  props: {
    selected: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    allowPaste: {
      type: Boolean,
      default: true,
    },
    showFullData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchLoading: false,
      allUserList: [],
      filterUserList: [],
    };
  },
  computed: {
    selectedUser() {
      if (this.multiple) {
        return this.selected ? this.selected.split(';').filter(name => name) : [];
      }
      return this.selected;
    },
  },
  async mounted() {
    // this.getAllUsers(); 不再使用该接口获取人员数据
    if (this.showFullData) {
      this.getUserData();
    }
  },
  methods: {
    // 获取全部用户
    async getUserData() {
      try {
        const staffNameList = await newSearchStaffList(this.selectedUser, 100);
        this.filterUserList = staffNameList.map(item => ({
          fullName: item.label,
          name: item.value,
        }));
      } catch (e) {
      }
    },

    onBlur(flag) {
      if (flag || !this.allowCreate || !this.allowPaste) return;
      if (this.multiple) {
        const nowSelected = Array.from(new Set([...this.selectedUser, this.queryStr]));

        if (nowSelected.length) {
          this.$emit('user-select-change', nowSelected.join(';'));
        }
      } else {
        this.$emit('user-select-change', this.queryStr);
      }
    },

    // 搜索
    async searchUser(query) {
      this.searchLoading = false;
      const queryStr = query.trim();
      if (queryStr) {
        try {
          const staffNameList = await newSearchStaffList(queryStr, 20);
          this.filterUserList = staffNameList.map(item => ({
            fullName: item.label,
            name: item.value,
          }));
        } catch (e) {}
        this.searchLoading = false;
      } else {
        this.filterUserList = [];
      }
      this.searchLoading = false;
    },

    // 选择人名
    onSelectChange(value) {
      let result = this.multiple ? value.join(';') : value.trim();
      if (typeof result === 'string') {
        result = result
          .split(';')
          .filter(name => name)
          .join(';');
      }
      this.$emit('user-select-change', result);
    },

    // 支持批量粘贴
    onPaste(event) {
      if (!this.allowPaste) return;
      event.preventDefault();
      let nowSelected = [];
      let pasteText = (event.clipboardData || window.clipboardData).getData('text');
      pasteText = pasteText
        .replace(/[,;\s]+/g, ';')
        .replace(/^;|;$/g, '')
        .replace(/\([\W]+\)/g, '');
      if (this.multiple) {
        nowSelected = Array.from(new Set([...this.selectedUser, ...pasteText.split(';')]));
        if (nowSelected.length) {
          this.$emit('user-select-change', nowSelected.join(';'));
        }
      } else {
        nowSelected = Array.from(new Set([...pasteText.split(';')]));
        if (nowSelected.length) {
          this.$emit('user-select-change', nowSelected[0]);
          this.searchUser(nowSelected[0]);
        }
      }
    },
  },
};
</script>

<style lang="less">
.user-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .user-avatar {
    width: 28px;
    height: 28px;
    border-radius: 14px;
  }
}
</style>
