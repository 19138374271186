import router from './router';
import store from './store';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import getPageTitle from '@/utils/get-page-title';

NProgress.configure({ showSpinner: true }); // NProgress 配置

const { getters, dispatch, commit, state } = store;
const { user, config } = state;


// const aegis = new Aegis({
//   id: getters.getConfigItem('aegis_key'), // 项目key
//   // uin: user.name, // 用户唯一 ID（可选）
//   // reportApiSpeed: true, // 接口测速
//   // reportAssetSpeed: true, // 静态资源测速
//   spa: true, // spa应用
// });

// Vue.config.errorHandler = (e) => {
//   aegis.error(e);
// };

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();
  // if (!config.healthId) {
  //   const timer = setInterval(async () => {
  //     if (location.pathname !== '/translate/business/login') {
  //       await getHealthCheck();
  //     }
  //   }, 10 * 60 * 1000);
  //   commit('config/SET_HEALTH_ID', timer);
  // }

  // set page title
  document.title = getPageTitle(to.meta.title);
  if (localStorage.getItem('AuthToken')) {
    sessionStorage.setItem('AuthToken', localStorage.getItem('AuthToken'));
  }

  NProgress.done();

  /** * TODO 这里判断页面权限 ***/
  // 方法有三种：
  // 1.路由meta中配置权限信息，比对用户权限来显示对应路由，以及权限错误提示
  // 2.开始时加载所有可操作的路由名称，不在里边的路由则提示 403
  // 3.每次都 ajax 请求权限

  // 存储路径

  next();
});

