export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

export function extractValidateErrorMessage(message) {
  const errMsg = [];
  const keys = Object.keys(message);
  keys.forEach((key) => {
    const list = message[key];
    list.forEach((item) => {
      if (errMsg.indexOf(item.message) !== -1) {
        return;
      }
      errMsg.push(item.message);
    });
  });
  return errMsg;
}
