import request from '@/http/request';
import store from '@/store';

// const relayApi = store.getters.getConfigItem('relay_api');
console.log(`当前环境：${process.env.NODE_ENV}`);
console.log(`当前使用的配置中心分组：${process.env.VUE_APP_CONFIG_GROUP}`);
// console.log(`当前使用的中间层API地址：${relayApi}`);
// const env = process.env.NODE_ENV === 'development' ? 'test' : 'product';

export function postRelay(path, params) {
  return request({
    url: `/relay${path}`,
    method: 'POST',
    data: {
      ...params,
      VueOperateUser: store.state.user.name,
    },
  });
}

// 请求到任意地址
export function postAny(url, params) {
  return request({
    url,
    method: 'POST',
    data: params,
    credentials: 'include' // 包含凭证信息
  });
}