import Layout from '@/layout';

// 团队管理
export default [
    {
        path: '/personnel',
        redirect: '/personnel/CN',
    },
    {
        path: '/personnel/CN',
        component: Layout,
        name: '/CN',
        meta: { title: '人员配置信息', icon: 'el-icon-user' },
        children: [
            {
                path: 'personnelAllocation',
                name: 'personnelAllocation',
                component: () => import('@/views/biBoard/personnelAllocation/index'),
                meta: { title: '人员配置', icon: 'el-icon-document' },
            },
            {
                path: 'a1',
                name: 'a1',
                component: () => import('@/views/appManage/test'),
                meta: { title: '人员管理', icon: 'el-icon-document' },
            },
            {
                path: 'train',
                name: 'train',
                component: () => import('@/views/personnel/cn/train'),
                meta: { title: '人员培训', icon: 'el-icon-document' },
            },

        ],
    },
    {
        path: '/personnel/audit',
        component: Layout,
        name: 'audit',
        meta: { title: '审计', icon: 'el-icon-user' },
        children: [
            {
                path: 'auditPlan',
                name: 'auditPlan',
                component: () => import('@/views/personnel/auditPlan/index'),
                meta: { title: '审计计划', icon: 'el-icon-document' },
            },
            {
                path: 'a1',
                name: 'a1',
                component: () => import('@/views/appManage/test'),
                meta: { title: '审计派单', icon: 'el-icon-document' },
            },
            {
                path: 'a2',
                name: 'a2',
                component: () => import('@/views/appManage/test'),
                meta: { title: '审计记录', icon: 'el-icon-document' },
            },
            {
                path: 'a4',
                name: 'a4',
                component: () => import('@/views/appManage/test'),
                meta: { title: '审计总结', icon: 'el-icon-document' },
            }
        ],
    },
];