export default [

  // 首页重新跳转
  { path: '/', redirect: '/appManage' },

  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    hidden: true,
  },

  // 403 无权限
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/403'),
    hidden: true,
  },

  // 404 页面需要放到最后
  { path: '*', redirect: '/404', hidden: true },
];
