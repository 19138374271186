import Layout from '@/layout';

// AI智能体
export default [
    {
        path: '/aiManage',
        redirect: '/aiManage/tool',
    },
    {
        path: '/aiManage/tool',
        component: Layout,
        name: '/tool',
        meta: { title: 'ai工具', icon: 'el-icon-user' },
        children: [
            {
                path: 'a1',
                name: 'a1',
                component: () => import('@/views/appManage/test'),
                meta: { title: 'Ping', icon: 'el-icon-document' },
            },
            {
                path: 'a2',
                name: 'a2',
                component: () => import('@/views/appManage/test'),
                meta: { title: 'SSH', icon: 'el-icon-document' },
            },
            {
                path: 'a3',
                name: 'a3',
                component: () => import('@/views/appManage/test'),
                meta: { title: '带外检查', icon: 'el-icon-document' },
            },
            {
                path: 'a4',
                name: 'a4',
                component: () => import('@/views/appManage/test'),
                meta: { title: 'Agent', icon: 'el-icon-document' },
            },
            {
                path: 'a5',
                name: 'a5',
                component: () => import('@/views/appManage/test'),
                meta: { title: 'SEL日志查询', icon: 'el-icon-document' },
            },
            {
                path: 'a6',
                name: 'a6',
                component: () => import('@/views/appManage/test'),
                meta: { title: 'SDR日志查询', icon: 'el-icon-document' },
            }
        ],
    },
    {
        path: '/aiManage/monitoringSystem',
        component: Layout,
        name: 'monitoringSystem',
        meta: { title: '远程管理', icon: 'el-icon-user' },
        children: [
            {
                path: 'operationPanel',
                name: 'operationPanel',
                component: () => import('@/views/appManage/monitoringSystem/operationPanel/index'),
                meta: { title: '顺风耳', icon: 'el-icon-document' },
            },
            {
                path: 'surveillanceVideo',
                name: 'surveillanceVideo',
                component: () => import('@/views/appManage/monitoringSystem/surveillanceVideo/index'),
                meta: { title: '千里眼', icon: 'el-icon-document' },
            }
        ],
    },
    {
        path: '/aiManage/assistant',
        component: Layout,
        name: 'assistant',
        meta: { title: 'ai助手', icon: 'el-icon-user' },
        children: [
            {
                path: 'assistant',
                name: 'assistant',
                component: () => import('@/views/aiManage/assistant/index'),
                meta: { title: 'ai助手', icon: 'el-icon-document' },
            }
        ],
    },
];