<template>
  <div id="app" class="netops-body">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="less">
#app {
  height: 100%;
  overflow-y: auto;
}
</style>
