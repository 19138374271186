import Vue from 'vue';
import Clipboard from 'v-clipboard';

import ElementUI from 'element-ui';
import store from './store';
import router from './router';
import { sync } from 'vuex-router-sync';
import App from './App.vue';
import './permission';

import 'normalize.css/normalize.css';
// import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/transition.css';
import '@/styles/index.less';
import '@/styles/elementUI.less';

import VueCompsitionAPI from '@vue/composition-api';
Vue.use(VueCompsitionAPI);

// 设置Element-UI全局配置，组件尺寸为small
Vue.use(ElementUI, { size: 'small' });
Vue.use(Clipboard);

// 利用 vuex-router-sync 组件同步vue-router的当前路由状态到store里去
sync(store, router);

Vue.config.productionTip = false;

Vue.config.devtools = false;

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
