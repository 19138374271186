"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pathResolve = exports.isUrl = void 0;
var isUrl = function (path) { return ['http://', 'https://'].some(function (item) { return path.startsWith(item); }); };
exports.isUrl = isUrl;
var pathResolve = function (parentPath, path) {
    if ((0, exports.isUrl)(path))
        return path;
    var childPath = !path ? path : "/".concat(path);
    return path.startsWith('/') ? path : "".concat(parentPath).concat(childPath).replace(/\/\//g, '/');
};
exports.pathResolve = pathResolve;
