var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticClass: "w",
      attrs: {
        value: _vm.selectedUser,
        remote: "",
        disabled: _vm.disabled,
        multiple: _vm.multiple,
        clearable: "",
        filterable: "",
        "value-key": "fullName",
        "allow-create": _vm.allowCreate,
        placeholder: _vm.placeholder,
        "remote-method": _vm.searchUser,
        loading: _vm.searchLoading,
        "default-first-option": true
      },
      on: { change: _vm.onSelectChange, "visible-change": _vm.onBlur },
      nativeOn: {
        paste: function($event) {
          return _vm.onPaste($event)
        }
      }
    },
    _vm._l(_vm.filterUserList, function(item, index) {
      return _c(
        "el-option",
        { key: index, attrs: { label: item.fullName, value: item.name } },
        [
          _c("div", { staticClass: "user-item" }, [
            _c("span", { staticClass: "f14 color-ntext" }, [
              _vm._v(" " + _vm._s(item.fullName) + " ")
            ])
          ])
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }