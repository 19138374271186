<template>
  <div class="navbar">
    <hamburger
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />
    <navTap class="nav-tap"></navTap>

    <div class="right-menu">
      <!-- <div class="www">
        <div></div>
        <button @click="login()" id="wx_reg">1111</button>
      </div> -->
      <el-dropdown class="avatar-container" trigger="click" placement="bottom">
        <div class="avatar-wrapper">
          <img
            v-if="user.avatar"
            :src="'data:image/jpeg;base64,' + user.avatar"
            class="user-avatar"
          />
          <span class="ml5" style="color: #f4f4f5">{{ user.keji_user_name || userid }}</span>
          <i class="ml5 el-icon-caret-bottom"></i>
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <router-link to="/">
            <el-dropdown-item> 首页 </el-dropdown-item>
          </router-link>
          <el-dropdown-item divided>
            <div @click="changeUser">退出登录</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Hamburger from '@/components/Hamburger';
import navTap from '@/components/navTap';
import UserSelect from '@/components/UserSelect';

export default {
  components: {
    Hamburger,
    navTap,
    UserSelect,
  },
  data() {
    return {
      // dialogVisible: false,
      name: this.$store.state.user.name,
      isAdmin: this.$store.state.user.isAdmin,
      isShowChangeUserDialog: false,
      changeUserName: '',
      userid: '',
    };
  },
  computed: {
    ...mapGetters(['sidebar', 'getConfigItem']),
    ...mapState(['user']),
  },
  async mounted() {
    const userId = localStorage.getItem('UserId');
    this.userid = userId;
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('setting/toggleSideBar');
    },
    changeUser() {
      localStorage.clear();
      window.location.href = '/translate/business/login';
    },
  },
};
</script>

<style lang="less" scoped>
.www {
  width: 100px;
  height: 200px;
  background: red;
}
.navbar {
  position: relative;
  height: 50px;
  background-color: #304156;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  overflow: hidden;

  .hamburger-container {
    float: left;
    height: 100%;
    line-height: 46px;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .nav-tap {
    display: inline-block;
    margin-left: 60px;
  }

  .right-ce {
    float: right;
    width: 104px;
    text-align: center;
    height: 100%;
    &:hover {
      background-color: rgb(32, 58, 69);
    }
    a {
      height: 50px !important;
      line-height: 50px;
      font-weight: bold;
      font-size: 16px;
      color: #f4f4f5;
    }
  }

  .right-menu {
    float: right;
    height: 100%;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;

    &:hover {
      border-color: #eee;
      background: rgba(0, 0, 0, 0.025);
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    }

    &:focus {
      outline: none;
    }

    .avatar-container {
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;

      .avatar-wrapper {
        display: flex;
        align-items: center;
        margin-top: 5px;
          height: 40px;

        .user-avatar {
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }
      }
    }
  }
}
</style>
