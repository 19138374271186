import Layout from '@/layout';

// 项目管理
export default [
    {
        path: '/projectManage',
        redirect: '/projectManage/project',
    },
    {
        path: '/projectManage/project',
        component: Layout,
        name: 'project',
        meta: { title: '项目', icon: 'el-icon-s-order' },
        children: [
            {
                path: 'a1',
                name: 'a1',
                component: () => import('@/views/appManage/test'),
                meta: { title: '马消IT项目', icon: 'el-icon-document' },
            },
            {
                path: 'a2',
                name: 'a2',
                component: () => import('@/views/appManage/test'),
                meta: { title: '珠海设施项目', icon: 'el-icon-document' },
            },
            {
                path: 'a3',
                name: 'a3',
                component: () => import('@/views/appManage/test'),
                meta: { title: '肇庆设施项目', icon: 'el-icon-document' },
            }
        ]
    }
];