import Layout from '@/layout';

// IT运维
export default [
  {
    path: '/appManage',
    redirect: '/appManage/monitoringSystem',
  },
  {
    path: '/appManage/monitoringSystem',
    component: Layout,
    name: 'monitoringSystem',
    meta: { title: '监控中心', icon: 'el-icon-s-platform' },
    children: [
      {
        path: 'operationPanel',
        name: 'operationPanel',
        component: () => import('@/views/appManage/monitoringSystem/operationPanel/index'),
        meta: { title: '运维监控', icon: 'el-icon-document' },
      },
      {
        path: 'surveillanceVideo',
        name: 'surveillanceVideo',
        component: () => import('@/views/appManage/monitoringSystem/surveillanceVideo/index'),
        meta: { title: '监控视频', icon: 'el-icon-document' },
      }
    ],
  },
  {
    path: '/appManage/monitor',
    component: Layout,
    name: 'monitor',
    meta: { title: '工单流程监控', icon: 'el-icon-s-grid' },
    children: [
      {
        path: 'itPOService',
        name: 'itPOService',
        component: () => import('@/views/appManage/monitor/itPOService/index'),
        meta: { title: '服务商IT PO单', icon: 'el-icon-tickets' },
      },
      {
        path: 'itPOService/detail',
        name: 'itPOService/detail',
        component: () => import('@/views/appManage/monitor/itPOService/detail'),
        meta: { title: '服务商IT PO单工单详情', icon: 'el-icon-tickets' },
        hidden: true,
      },
      {
        path: 'networkRushTicket',
        name: 'networkRushTicket',
        component: () => import('@/views/appManage/monitor/networkRushTicket/index'),
        meta: { title: '网络催办工单', icon: 'el-icon-tickets' },
      },
      {
        path: 'networkRushTicket/detail',
        name: 'networkRushTicket/detail',
        component: () => import('@/views/appManage/monitor/networkRushTicket/detail'),
        meta: { title: '网络催办工单详情', icon: 'el-icon-tickets' },
        hidden: true,
      },
      {
        path: 'serverDecommissioning',
        name: 'serverDecommissioning',
        component: () => import('@/views/monitor/serverDecommissioning/index'),
        meta: { title: '服务器退役', icon: 'el-icon-document' },
      },
      {
        path: 'serverDecommissioning/detail',
        name: 'serverDecommissioning/detail',
        component: () => import('@/views/monitor/serverDecommissioning/detail'),
        meta: { title: '服务器退役工单详情', icon: 'el-icon-s-operation' },
        hidden: true,
      },
      {
        path: 'serverRelocation',
        name: 'serverRelocation',
        component: () => import('@/views/monitor/serverRelocation/index'),
        meta: { title: '服务器搬迁', icon: 'el-icon-document' },
      },
      {
        path: 'serverRelocation/detail',
        name: 'serverRelocation/detail',
        component: () => import('@/views/monitor/serverRelocation/detail'),
        meta: { title: '服务器搬迁工单详情', icon: 'el-icon-s-operation' },
        hidden: true,
      },
      {
        path: 'TPC_Abnormal',
        name: 'TPC_Abnormal',
        component: () => import('@/views/monitor/TPC_Abnormal/index'),
        meta: { title: 'TPC异常', icon: 'el-icon-document' },
      },
      {
        path: 'TPC_Abnormal/detail',
        name: 'TPC_Abnormal/detail',
        component: () => import('@/views/monitor/TPC_Abnormal/detail'),
        meta: { title: 'TCP异常工单详情', icon: 'el-icon-s-operation' },
        hidden: true,
      },
      {
        path: 'networkMalfunction',
        name: 'networkMalfunction',
        component: () => import('@/views/monitor/networkMalfunction/index'),
        meta: { title: '网络故障', icon: 'el-icon-document' },
      },
      {
        path: 'networkMalfunction/detail',
        name: 'networkMalfunction/detail',
        component: () => import('@/views/monitor/networkMalfunction/detail'),
        meta: { title: '网络故障工单详情', icon: 'el-icon-s-operation' },
        hidden: true,
      },
      {
        path: 'ticketManage',
        name: 'ticketManage',
        component: () => import('@/views/appManage/monitor/ticketManage/index'),
        meta: { title: '工单管控催办', icon: 'el-icon-document' },
      },
    ],
  },
  {
    path: '/appManage/processStatistics',
    component: Layout,
    name: 'processStatistics',
    meta: { title: '工单统计', icon: 'el-icon-menu' },
    children: [
      {
        path: 'serverDecommissioning',
        name: 'serverDecommissioning',
        component: () => import('@/views/processStatistics/serverDecommissioning/index'),
        meta: { title: '服务器退役', icon: 'el-icon-document' },
      },
      {
        path: 'serverRelocation',
        name: 'serverRelocation',
        component: () => import('@/views/processStatistics/serverRelocation/index'),
        meta: { title: '服务器搬迁', icon: 'el-icon-document' },
      },
      {
        path: 'TPC_Abnormal',
        name: 'TPC_Abnormal',
        component: () => import('@/views/processStatistics/TPC_Abnormal/index'),
        meta: { title: 'TPC异常', icon: 'el-icon-document' },
      },
      {
        path: 'networkMalfunction',
        name: 'networkMalfunction',
        component: () => import('@/views/processStatistics/networkMalfunction/index'),
        meta: { title: '网络故障', icon: 'el-icon-document' },
      },
    ],
  },
  {
    path: '/appManage/statementStatistics',
    component: Layout,
    name: 'statementStatistics',
    meta: { title: '结单统计', icon: 'el-icon-s-marketing' },
    children: [
      {
        path: 'serverDecommissioning',
        name: 'serverDecommissioning',
        component: () => import('@/views/statementStatistics/serverDecommissioning/index'),
        meta: { title: '服务器退役', icon: 'el-icon-document' },
      },
      {
        path: 'serverRelocation',
        name: 'serverRelocation',
        component: () => import('@/views/statementStatistics/serverRelocation/index'),
        meta: { title: '服务器搬迁', icon: 'el-icon-document' },
      },
      {
        path: 'TPC_Abnormal',
        name: 'TPC_Abnormal',
        component: () => import('@/views/statementStatistics/TPC_Abnormal/index'),
        meta: { title: 'TPC异常', icon: 'el-icon-document' },
      },
      {
        path: 'networkMalfunction',
        name: 'networkMalfunction',
        component: () => import('@/views/statementStatistics/networkMalfunction/index'),
        meta: { title: '网络故障', icon: 'el-icon-document' },
      },
    ],
  },
  {
    path: '/appManage/biBoard',
    component: Layout,
    name: 'biBoard',
    meta: { title: '业务看板', icon: 'el-icon-s-data' },
    children: [
        {
        path: 'poWorkOrder',
        name: 'poWorkOrder',
        component: () => import('@/views/biBoard/poWorkOrder/index'),
        meta: { title: 'PO单统计', icon: 'el-icon-document' },
        },{
            path: 'focus',
            name: 'focus',
            component: () => import('@/views/biBoard/focus/index'),
            meta: { title: '集中统计', icon: 'el-icon-document' },
        },
        {
          path: 'serverMove',
          name: 'serverMove',
          component: () => import('@/views/biBoard/serverMove/index'),
          meta: { title: '服务器搬迁统计', icon: 'el-icon-document' },
        },
        {
          path: 'serverRetire',
          name: 'serverRetire',
          component: () => import('@/views/biBoard/serverRetire/index'),
          meta: { title: '服务器退役统计', icon: 'el-icon-document' },
        },
        {
          path: 'serverTpc',
          name: 'serverTpc',
          component: () => import('@/views/biBoard/serverTpc/index'),
          meta: { title: '服务器TPC统计', icon: 'el-icon-document' },
        },
        {
          path: 'networkMalfunction',
          name: 'networkMalfunction',
          component: () => import('@/views/biBoard/networkMalfunction/index'),
          meta: { title: '网络故障统计', icon: 'el-icon-document' },
        },
        {
          path: 'POremind',
          name: 'POremind',
          component: () => import('@/views/biBoard/POremind/index'),
          meta: { title: '工单管控催办统计', icon: 'el-icon-document' },
        },
        {
          path: 'TencentNetwork',
          name: 'TencentNetwork',
          component: () => import('@/views/biBoard/TencentNetwork/index'),
          meta: { title: '网络催办工单统计', icon: 'el-icon-document' },
        }
    ],
  },
  {
    path: '/appManage/dispatchingDesk',
    component: Layout,
    name: 'dispatchingDesk',
    redirect: '/dispatchingDesk/a1',
    meta: { title: '调度管理', icon: 'el-icon-s-order' },
    children: [
        {
            path: 'a1',
            name: 'a1',
            component: () => import('@/views/appManage/test'),
            meta: { title: '工单调度', icon: 'el-icon-document' },
        },
        {
            path: 'a2',
            name: 'a2',
            component: () => import('@/views/appManage/test'),
            meta: { title: '人员调度', icon: 'el-icon-document' },
        },
        {
            path: 'a3',
            name: 'a3',
            component: () => import('@/views/appManage/test'),
            meta: { title: '调度交付', icon: 'el-icon-document' },
        },
    ],
  },
];
