<template>
  <div class=" has-logo">
    <logo :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        background-color="#ebecf0"
        text-color="rgba(0,10,41,.6)"
        :unique-opened="false"
        :default-openeds="defaultOpeneds"
        active-text-color="#409EFF"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Logo from './Logo';
import SidebarItem from './SidebarItem';
import '@/styles/sidebar.less';

export default {
  components: {
    SidebarItem,
    Logo,
  },
  mounted() {
    window.$wujie?.bus.$on("sidebar", (status) => {
      this.$store.dispatch('setting/setSidebar', status);
    });
  },
  computed: {
    ...mapGetters(['sidebar']),
    routes() {
      const [, option] = this.$route.path.split('/');
      switch (option) {
        case 'appManage':
          return this.$router.options.appManage;
        case 'facility':
          return this.$router.options.facility;
        case 'assetManage':
          return this.$router.options.assetManage;
        case 'personnel':
          return this.$router.options.personnel;
        case 'projectManage':
          return this.$router.options.projectManage;
        case 'partner':
          return this.$router.options.partner;
        case 'knowledgeBase':
          return this.$router.options.knowledgeBase;
        case 'aiManage':
          return this.$router.options.aiManage;
        case 'idcpManage':
          return this.$router.options.idcpManage;
        default:
          return [];
      }
    },
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
    defaultOpeneds() {
      return [
      ];
    },
  },
};
</script>
