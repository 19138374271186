<template>
    <div class="login-container">
        <el-card class="login-card" shadow="never">
            <h2 class="title">登录</h2>
            <el-form :model="form" ref="form" label-width="0" @submit.prevent="handleLogin">
                <el-form-item prop="username">
                    <el-input v-model="form.username" placeholder="请输入用户名" prefix-icon="el-icon-user">
                    </el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input type="password" v-model="form.password" placeholder="请输入密码" prefix-icon="el-icon-lock">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleLogin" :loading="isLoading"
                        style="width: 100%;">登录</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-dialog title="错误" :visible.sync="dialogVisible" width="300px" @close="resetForm">
            <p>{{ error }}</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">确认</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                username: '',
                password: ''
            },
            error: '',
            isLoading: false,
            dialogVisible: false, // 控制对话框的显示
            previousRoute: null,
        };
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.previousRoute = from.fullPath;
        });
    },
    methods: {
        async handleLogin() {
            this.error = ''; // 重置错误信息
            this.isLoading = true; // 开始加载
            try {
                const response = await this.login();
                if (response.success) {
                    localStorage.setItem('AuthToken', response.data.AuthToken);
                    localStorage.setItem('UserId', response.data?.UserId)
                    this.$router.push(this.previousRoute || '/appManage');
                } else {
                    this.error = response.msg;
                    this.dialogVisible = true; // 打开错误提示对话框
                }
            } catch (error) {
                this.error = error;
                this.dialogVisible = true; // 打开错误提示对话框
            } finally {
                this.isLoading = false; // 结束加载
            }
        },
        resetForm() {
            // 清空表单数据
            // this.form.username = '';
            // this.form.password = '';
        },
        async login() {
            // if (this.form.username === 'admin' && this.form.password === '123456') {
            //     return {
            //         success: 1,
            //         AuthToken: 'Kj7K4p9Qn6m3zL1b8vN5rD2tY0sWx4'
            //     };
            // } else {
            //     return {
            //         success: 0,
            //         AuthToken: ''
            //     };
            // }
            return import('../api/login/login').then(module => module.default.login(this.form.username, this.form.password));
        }
    }
};
</script>

<style scoped>
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.login-card {
    width: 400px;
    /* 设置卡片宽度 */
    padding: 20px;
}

.title {
    text-align: center;
    margin-bottom: 20px;
}
</style>