import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import setting from './modules/setting';
import config from './modules/config';
import getters from './getters';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    setting,
    config,
  },
  getters,
});

export default store;
