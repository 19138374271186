import Layout from '@/layout';

// 园区管理
export default [
    {
        path: '/idcpManage',
        redirect: '/idcpManage/accessManagement',
    },
    {
        path: '/idcpManage/accessManagement',
        component: Layout,
        name: '/accessManagement',
        meta: { title: '进出管理', icon: 'el-icon-s-claim' },
        children: [
            {
                path: 'a1', 
                name: 'a1',
                component: () => import('@/views/appManage/test'),
                meta: { title: '出入管理申请', icon: 'el-icon-document' },
            },            {
                path: 'a2',
                name: 'a2',
                component: () => import('@/views/appManage/test'),
                meta: { title: '出入管理登记', icon: 'el-icon-document' },
            },            {
                path: 'a3',
                name: 'a3',
                component: () => import('@/views/appManage/test'),
                meta: { title: '工单查询', icon: 'el-icon-document' },
            }
        ],
    },
    {
        path: '/idcpManage/assetAccess',
        component: Layout,
        name: 'assetAccess',
        meta: { title: '物资放行', icon: 'el-icon-s-check' },
        children: [
            {
                path: 'a1', 
                name: 'a1',
                component: () => import('@/views/appManage/test'),
                meta: { title: '物资放行申请', icon: 'el-icon-document' },
            },            {
                path: 'a2',
                name: 'a2',
                component: () => import('@/views/appManage/test'),
                meta: { title: '物资放行登记', icon: 'el-icon-document' },
            },            {
                path: 'a3',
                name: 'a3',
                component: () => import('@/views/appManage/test'),
                meta: { title: '工单查询', icon: 'el-icon-document' },
            }
        ],
    },
    {
        path: '/idcpManage/cageAccess',
        component: Layout,
        name: 'cageAccess',
        meta: { title: '围笼进出', icon: 'el-icon-share' },
        children: [
            {
                path: 'a1', 
                name: 'a1',
                component: () => import('@/views/appManage/test'),
                meta: { title: '物资放行申请', icon: 'el-icon-document' },
            },            {
                path: 'a2',
                name: 'a2',
                component: () => import('@/views/appManage/test'),
                meta: { title: '物资放行登记', icon: 'el-icon-document' },
            }
        ],
    },
    {
        path: '/idcpManage/basicConfiguration',
        component: Layout,
        name: 'basicConfiguration',
        meta: { title: '基础配置', icon: 'el-icon-s-tools' },
        children: [
            {
                path: 'a1', 
                name: 'a1',
                component: () => import('@/views/appManage/test'),
                meta: { title: '门岗配置', icon: 'el-icon-document' },
            },            
            {
                path: 'a2',
                name: 'a2',
                component: () => import('@/views/appManage/test'),
                meta: { title: '人员进出模式配置', icon: 'el-icon-document' },
            },         
            {
                path: 'a3',
                name: 'a3',
                component: () => import('@/views/appManage/test'),
                meta: { title: '物资放行模式配置', icon: 'el-icon-document' },
            }
        ],
    },
];