import Layout from '@/layout';

// 知识库
export default [
    {
        path: '/knowledgeBase',
        redirect: '/knowledgeBase/document',
    },
    {
        path: '/knowledgeBase/document',
        component: Layout,
        name: 'document',
        meta: { title: '知识库', icon: 'el-icon-s-order' },
        children: [
            {
                path: 'a1',
                name: 'a1',
                component: () => import('@/views/appManage/test'),
                meta: { title: 'IT文档', icon: 'el-icon-document' },
            },
            {
                path: 'a2',
                name: 'a2',
                component: () => import('@/views/appManage/test'),
                meta: { title: '设施文档', icon: 'el-icon-document' },
            },
            {
                path: 'a3',
                name: 'a3',
                component: () => import('@/views/appManage/test'),
                meta: { title: '管理制度', icon: 'el-icon-document' },
            },
            {
                path: 'operationDangerous',
                name: 'operationDangerous',
                component: () => import('@/views/knowledgeBase/operationDangerous/index'),
                meta: { title: '运维红线', icon: 'el-icon-document' },
            },
            {
                path: 'SOPmanage',
                name: 'SOPmanage',
                component: () => import('@/views/knowledgeBase/SOPmanage'),
                meta: { title: 'SOP管理', icon: 'el-icon-document' },
            },
            {
                path: 'a6',
                name: 'a6',
                component: () => import('@/views/appManage/test'),
                meta: { title: 'EHS管理', icon: 'el-icon-document' },
            },
        ]
    }
];