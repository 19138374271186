import { Message } from 'element-ui';

// 定义的state初始值
const state = {
  items: [],
  group: '',
  version_id: '',
  healthId: '',
};

// 定义的state的初始值方法，传入state或者额外的方法，然后利用vuex的双向数据驱动进行值的改变
// 可通过this.$store.commit(' ')调用，但是触发的是同步事件
const mutations = {
  SET_APP_CONFIG(state, config) {
    state.items = config.items;
    state.group = config.group;
    state.version_id = '';
  },
  SET_HEALTH_ID(state, id) {
    state.healthId = id;
  },
};

// 使用actions的好处在于不会触发同步时间，而是异步事件
// actions里面自定义的函数接收一个context参数和要变化的形参，context与store实例具有相同的方法和属性，所以它可以执行context.commit(' ')
const actions = {
  // 从配置中心获取配置
  async getConfigFromRainbow(context) {
    try {
    } catch (err) {
      // console.log(111);
      Message.error({ message: `获取配置中心接口错误：${err.message}` });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
