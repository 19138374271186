var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _c("navTap", { staticClass: "nav-tap" }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container",
              attrs: { trigger: "click", placement: "bottom" }
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _vm.user.avatar
                  ? _c("img", {
                      staticClass: "user-avatar",
                      attrs: {
                        src: "data:image/jpeg;base64," + _vm.user.avatar
                      }
                    })
                  : _vm._e(),
                _c(
                  "span",
                  { staticClass: "ml5", staticStyle: { color: "#f4f4f5" } },
                  [_vm._v(_vm._s(_vm.user.keji_user_name || _vm.userid))]
                ),
                _c("i", { staticClass: "ml5 el-icon-caret-bottom" })
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [_c("el-dropdown-item", [_vm._v(" 首页 ")])],
                    1
                  ),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c("div", { on: { click: _vm.changeUser } }, [
                      _vm._v("退出登录")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }