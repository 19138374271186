import request from '@/http/request';

export default {
    async login(username, password) {
        const response = await request({
            url: '/login/AuthToken',
            method: 'POST',
            data: {
                username: username,
                password: password
            }
        });
        return response.data;
    },

    async validateToken(AuthToken, UserId) {
        const response = await request({
            url: '/login/AuthToken_check',
            method: 'POST',
            data: {
                "AuthToken": AuthToken,
                "UserId": UserId
            }
        });

        // 假设返回的数据结构中包含一个success字段
        let ret_bool = false;
        if (response.data.success === 1) {
            ret_bool = true;
        }
        return ret_bool;
    }
};
