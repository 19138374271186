var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { background: "#304156" } },
    _vm._l(_vm.routers, function(item, i) {
      return _c(
        "ElPopover",
        {
          key: item.name,
          ref: item.name,
          refInFor: true,
          staticClass: "mr10",
          attrs: {
            "popper-class": [
              "menu-pop",
              !item.isFlat
                ? "submenu-length-" + (item.children ? item.children.length : 0)
                : "submenu-length-0"
            ].join(" "),
            trigger: "hover",
            placement: "bottom-start",
            "visible-arrow": false,
            "close-delay": 50
          },
          scopedSlots: _vm._u(
            [
              {
                key: "reference",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        class: [
                          "right-menu right-ce mr10",
                          item.name,
                          { active: _vm.menuShow[item.name] }
                        ],
                        style: {
                          tops: 0,
                          left: 110 + 104 * i + "px"
                        },
                        on: {
                          click: function($event) {
                            return _vm.onClickNav(item.name)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          { class: { selected: item.name === _vm.activeMenu } },
                          [_vm._v(_vm._s(_vm.getTitle(item)))]
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          ),
          model: {
            value: _vm.menuShow[item.name],
            callback: function($$v) {
              _vm.$set(_vm.menuShow, item.name, $$v)
            },
            expression: "menuShow[item.name]"
          }
        },
        [
          _c(
            "ul",
            {
              class: [
                { "submenu-wrap": !item.isFlat },
                !item.isFlat
                  ? "submenu-wrap-length-" +
                    (item.children ? item.children.length : 0)
                  : "flat-menu"
              ]
            },
            [
              item.isFlat
                ? _c(
                    "div",
                    _vm._l(item.children, function(child) {
                      return _c(
                        "div",
                        { key: child.name, staticClass: "lastmenu-wrap" },
                        [
                          _c(
                            "el-link",
                            {
                              staticClass: "lastmenu-item mr10",
                              attrs: { underline: false },
                              on: {
                                click: function($event) {
                                  _vm.onMenuSelect(
                                    child.path,
                                    item.name,
                                    _vm.getMeta(child).outLink
                                  )
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.getTitle(child)) + " ")]
                          ),
                          _vm.getMeta(child).isNew
                            ? _c(
                                "span",
                                { staticClass: "new-icon text-12px" },
                                [_vm._v("NEW")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c(
                    "div",
                    _vm._l(item.children, function(child) {
                      return _c(
                        "li",
                        { key: child.name, staticClass: "submenu-item" },
                        [
                          _c("span", { staticClass: "submenu-title mb10" }, [
                            _vm._v(" " + _vm._s(_vm.getTitle(child)) + " ")
                          ]),
                          child.children &&
                          child.children.length &&
                          !child.onlyOneChild
                            ? _c(
                                "div",
                                _vm._l(child.children, function(last) {
                                  return _c(
                                    "div",
                                    {
                                      key: last.name,
                                      staticClass: "lastmenu-wrap"
                                    },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          staticClass: "lastmenu-item mr10",
                                          attrs: { underline: false },
                                          on: {
                                            click: function($event) {
                                              _vm.onMenuSelect(
                                                last.path,
                                                item.name,
                                                _vm.getMeta(last).outLink
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.getTitle(last)) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _vm.getMeta(last).isNew
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "new-icon text-12px"
                                            },
                                            [_vm._v("NEW")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _c(
                                "div",
                                { staticClass: "lastmenu-wrap" },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      staticClass: "lastmenu-item",
                                      attrs: { underline: false },
                                      on: {
                                        click: function($event) {
                                          _vm.onMenuSelect(
                                            child.path,
                                            item.name,
                                            _vm.getMeta(child.onlyOneChild)
                                              .outLink
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.getTitle(child)) + " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                        ]
                      )
                    }),
                    0
                  )
            ]
          )
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }