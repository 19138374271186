import Layout from '@/layout';

// 设施运维
export default [
    {
        path: '/facility',
        redirect: '/facility/routingInspection',
    },
    {
        path: '/facility/facilitySystem',
        component: Layout,
        name: 'facilitySystem',
        meta: { title: '设施系统', icon: 'el-icon-s-order' },
        children: [
            {
                path: 'powerDistribution',
                name: 'powerDistribution',
                component: () => import('@/views/facility/facilitySystem/powerDistribution'),
                meta: { title: '配电系统', icon: 'el-icon-document' },
            },
            {
                path: 'airConditioner',
                name: 'airConditioner',
                component: () => import('@/views/facility/facilitySystem/airConditioner'),
                meta: { title: '空调系统', icon: 'el-icon-document' },
            },
            {
                path: 'OilEngine',
                name: 'OilEngine',
                component: () => import('@/views/facility/facilitySystem/OilEngine'),
                meta: { title: '油机系统', icon: 'el-icon-document' },
            },
            {
                path: 'weakCurrent',
                name: 'weakCurrent',
                component: () => import('@/views/facility/facilitySystem/weakCurrent'),
                meta: { title: '弱电系统', icon: 'el-icon-document' },
            }
        ]
    },
    {
        path: '/facility/facilityOperationMonitoring',
        component: Layout,
        name: 'facilityOperationMonitoring',
        meta: { title: '设施运营监控', icon: 'el-icon-s-order' },
        children: [
            {
                path: 'a1',
                name: 'a1',
                component: () => import('@/views/appManage/test'),
                meta: { title: '监控管理', icon: 'el-icon-document' },
            },
            {
                path: 'a2',
                name: 'a2',
                component: () => import('@/views/appManage/test'),
                meta: { title: '事件工单', icon: 'el-icon-document' },
            }
        ]
    },
    {
        path: '/facility/routingInspection',
        component: Layout,
        name: 'routingInspection',
        meta: { title: '设施巡检管理', icon: 'el-icon-s-order' },
        children: [
            {
                path: 'a1',
                name: 'a1',
                component: () => import('@/views/appManage/test'),
                meta: { title: '巡检派单管理', icon: 'el-icon-document' },
            },
            {
                path: 'a2',
                name: 'a2',
                component: () => import('@/views/appManage/test'),
                meta: { title: '巡检规划', icon: 'el-icon-document' },
            },
            {
                path: 'a3',
                name: 'a3',
                component: () => import('@/views/appManage/test'),
                meta: { title: '巡检记录查询', icon: 'el-icon-document' },
            }
        ]
    },
    {
        path: '/facility/facilityYaintenanceManagement',
        component: Layout,
        name: 'facilityYaintenanceManagement',
        meta: { title: '设施维保管理', icon: 'el-icon-s-order' },
        children: [
            {
                path: 'MaintenancePlan',
                name: 'MaintenancePlan',
                component: () => import('@/views/facility/facilityYaintenanceManagement/MaintenancePlan/index'),
                meta: { title: '维保行事厉', icon: 'el-icon-document' },
            },
            {
                path: 'a2',
                name: 'a2',
                component: () => import('@/views/appManage/test'),
                meta: { title: '维修工单', icon: 'el-icon-document' },
            }
        ]
    },
    {
        path: '/facility/facilityChangeManagement',
        component: Layout,
        name: 'facilityChangeManagement',
        meta: { title: '设施变更管理', icon: 'el-icon-s-order' },
        children: [
            {
                path: 'a1',
                name: 'a1',
                component: () => import('@/views/appManage/test'),
                meta: { title: '变更申请', icon: 'el-icon-document' },
            },
            {
                path: 'a2',
                name: 'a2',
                component: () => import('@/views/appManage/test'),
                meta: { title: '变更工作台', icon: 'el-icon-document' },
            }
        ]
    },
    {
        path: '/facility/facilityRehearsalManagement',
        component: Layout,
        name: 'facilityRehearsalManagement',
        meta: { title: '设施演练管理', icon: 'el-icon-s-order' },
        children: [
            {
                path: 'a1',
                name: 'a1',
                component: () => import('@/views/appManage/test'),
                meta: { title: '演练方案管理', icon: 'el-icon-document' },
            },
            {
                path: 'DrillPlan',
                name: 'DrillPlan',
                component: () => import('@/views/facility/facilityYaintenanceManagement/DrillPlan/index'),
                meta: { title: '演练计划管理', icon: 'el-icon-document' },
            },
            {
                path: 'a3',
                name: 'a3',
                component: () => import('@/views/appManage/test'),
                meta: { title: '演练记录查询', icon: 'el-icon-document' },
            },
            {
                path: 'a4',
                name: 'a4',
                component: () => import('@/views/appManage/test'),
                meta: { title: '演练计划进度表', icon: 'el-icon-document' },
            }
        ]
    },
    {
        path: '/facility/facilityIssuesManagement',
        component: Layout,
        name: 'facilityIssuesManagement',
        meta: { title: '设施问题管理', icon: 'el-icon-s-order' },
        children: [
            {
                path: 'a1',
                name: 'a1',
                component: () => import('@/views/appManage/test'),
                meta: { title: '问题列表', icon: 'el-icon-document' },
            }
        ]
    },
    {
        path: '/facility/facilityTrainingManagement',
        component: Layout,
        name: 'facilityTrainingManagement',
        meta: { title: '设施培训管理', icon: 'el-icon-s-order' },
        children: [
            {
                path: 'a1',
                name: 'a1',
                component: () => import('@/views/appManage/test'),
                meta: { title: '培训计划', icon: 'el-icon-document' },
            },
            {
                path: 'a2',
                name: 'a2',
                component: () => import('@/views/appManage/test'),
                meta: { title: '培训记录查询', icon: 'el-icon-document' },
            },
            {
                path: 'a3',
                name: 'a3',
                component: () => import('@/views/appManage/test'),
                meta: { title: '培训课程', icon: 'el-icon-document' },
            },
            {
                path: 'a4',
                name: 'a4',
                component: () => import('@/views/appManage/test'),
                meta: { title: '培训考试成绩', icon: 'el-icon-document' },
            }
        ]
    }
];