<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </section>
</template>
<script>
import Watermark from '@/components/WaterMark/watermark.js';
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path;
    },
    isMobile() {
      return /phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(
        navigator.userAgent
      );
    },
  },
  mounted() {
    console.log(process.env.NODE_ENV,"process.env.NODE_ENV");
    if (process.env.NODE_ENV === 'development') {
          Watermark.set(localStorage.getItem('UserId') ? '测试环境' + localStorage.getItem('UserId') : '', '');
    } else {
       Watermark.set(localStorage.getItem('UserId') ? '测试环境' + localStorage.getItem('UserId') : '', '');
      // console.log("走正式环境");
      //           Watermark.set(localStorage.getItem('UserId'), '');

    }
  },
};
</script>
<style lang="less" scoped>
.app-main {
  /*50 = navbar  */
  height: calc(100vh - 50px);
  width: 100%;
  position: relative;
}
.fixed-header + .app-main {
  padding-top: 50px;
}
.test-env {
  position: fixed;
  right: 20px;
  top: 55px;
  opacity: 0.22;
  z-index: 9999999999;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
  font-size: 24px;
}
</style>
<style lang="less">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>